<!--购物车结算-->
<template>
<div class="pay">
    <topnav active="pay"></topnav>
    <topmenu active="pay"></topmenu>
    <div class="pay_wrap public_width">
        <h3 class="pay_title">订单确认</h3>
        <div class="settlement_main">
            <!-- 确认收货地址 -->
            <div class="com_box">
                <div class="com_title">确认收货地址<div class="trans_line"></div></div>
                
                <div class="box_main" :class="{ has_address_box: address_show }">
                    <!-- 没有地址时 -->
                    <el-form
                        :model="address_form"
                        :rules="address_rules"
                        ref="form_addr"
                        label-width="100px"
                        v-if="address_show === false"
                    >
                        <el-form-item label="收货人:" prop="name">
                            <el-input v-model="address_form.name"></el-input>
                        </el-form-item>

                        <el-form-item label="所在地区:" prop="district">
                            <el-cascader
                            v-model="address_form.district"
                            :options="options"
                            :props="{ expandTrigger: 'hover' }"
                            @change="cascaderChange"
                            ></el-cascader>
                        </el-form-item>

                        <el-form-item label="详细地址:" prop="address">
                            <el-input v-model="address_form.address"></el-input>
                        </el-form-item>

                        <el-form-item label="手机/固话:" prop="tel">
                            <el-input v-model="address_form.tel"></el-input>
                        </el-form-item>

                        <el-form-item label="邮政编码:">
                            <el-input v-model="address_form.postcode"></el-input>
                        </el-form-item>

                        <el-form-item>
                            <el-button type="primary" @click="sub_address_form()">保存</el-button>
                        </el-form-item>
                    </el-form>

                    <!-- 有地址 循环展示 -->
                    <div v-if="address_show === true">
                        <div
                            class="item_address"
                            :class="{ active_address: active_index == index }"
                            v-for="(item, index) in addressList"
                            :key="index"
                            @click="toggle_address(index)"
                        >
                            <div class="left_txt">寄送到</div>
                            <div class="readio_box">
                                <div class="com_radio"></div>
                                <div class="active_radio"></div>
                            </div>

                            <div class="address_box">
                                <span class="district_txt"> {{ item.district.replace(/[/]/g,"")}}</span>
                                <span class="address_txt"> {{ item.address }}</span>
                                （ <span class="name_txt"> {{ item.name }} </span>）收
                                <span class="tel_txt"> {{ item.tel }}</span>
                            </div>

                            <div class="default_address" v-if="item.default == '是'">默认地址</div>
                        </div>

                        <div class="add_address_btn" @click="changeAddress(true)">
                            新增收货地址
                        </div>
                    </div>
                </div>
            </div>

            <!-- 确认产品信息 -->
            <div class="com_box" v-if="shoppingCarList.length > 0">
                <div class="com_title">确认产品信息<div class="trans_line"></div></div>

                <div class="box_main">
                    <div class="pay_table">
                        <div class="payMain_hd">
                            <ul class="order_lists d-flex payTop">
                                <li class="list_con">图片</li>
                                <li class="list_product">产品</li>
                                <li class="list_desc">参数描述</li>
                                <li class="list_price">单价</li>
                                <li class="list_num">数量</li>
                                <li class="list_total">小计</li>
                            </ul>
                        </div>
                        <div class="payBox">
                            <div class="order_content">
                                <ul
                                    class="order_lists"
                                    v-for="(item, index) in shoppingCarList"
                                    :key="index"
                                >
                                    <!-- 图片 -->
                                    <li class="list_con">
                                      <div class="list_img">
                                        <img :src="item.thumbs ? item.thumbs : ''" alt="" />
                                      </div>
                                    </li>

                                    <!-- 产品名称 -->
                                    <li class="list_product">
                                      <!-- 产品名称 -->
                                      <div class="list_name" @click="to_detail(item)">{{ item.goods_name }}</div>
                                      <!-- 规格型号 -->
                                      <div class="list_item"><span class="title">规格型号</span><span class="val">{{item.standard}}</span></div>
                                      <!--封装 -->
                                      <div class="list_item"><span class="title">封装</span><span class="val">{{item.package}}</span></div>
                                    </li>

                                    <!-- 参数描述 -->
                                    <li class="list_desc">
                                        <el-popover
                                          popper-class="pb_pop"
                                          placement="bottom-start"
                                          :width="200"
                                          trigger="hover">
                                              <template v-slot:reference> 
                                                  <div class="text-seven">
                                                      <div v-if="item.filters && item.filters.length > 0">
                                                          <p v-for="(v) in item.filters" :key="(v)">{{v.filter_name}} : {{v.value}}</p>
                                                      </div>
                                                      <p v-else>{{ item.desc }}</p>
                                                  </div>	
                                              </template>
                                              <div v-if="item.filters && item.filters.length > 0">
                                                  <p v-for="(v) in item.filters" :key="(v)">{{v.filter_name}} : {{v.value}}</p>
                                              </div>
                                              <p v-else>{{ item.desc }}</p>
                                          </el-popover>        
                                    </li>

                                    <!-- 单价 -->
                                    <li class="list_price">￥{{ item.price_real }}</li>
                                    
                                    <!-- 数量 -->
                                    <li class="list_num">{{ item.num  }}</li>

                                    <!-- 小计 -->
                                    <li class="list_total">
                                        <div class="sum_price">￥{{item.money}}</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 发票信息 展示 -->
            <div class="com_box invoice_box" >
                <div class="com_title">开票信息<div class="trans_line"></div></div>

                <div class="box_main">
                    <div class="choice d-flex">
                        <span class="choice_title">开票类型:</span>
                        <el-radio-group class="d-flex" v-model="check_type" @change="change_type">
                            <el-radio :label="'不开票'">不开票</el-radio>
                            <el-radio :label="'电子发票'">电子发票</el-radio>
                            <el-radio :label="'增值税普票'">增值税普票</el-radio>
                            <el-radio :label="'增值税专票'">增值税专票</el-radio>
                        </el-radio-group>
                    </div>

                    <div class="form" v-if="check_type != '不开票'">
                        <div class="warm_prompt d-flex">
                            <div>温馨提示：</div>
                            <div>请确保所填开票信息与贵公司税务登记证一致。发票将于订单签收后第二个工作日开具（特殊情况无法受理除外，节假日顺延），并发送至邮箱。</div>
                        </div>

                        <div class="check_detail" >
                            <!-- 无发票 -->
                            <div v-if="has_invoice === false">
                                <el-form :model="invoiceInfo" ref="form_invoice" :rules="invoice_rules" label-width="100px"
                                >
                                    <el-form-item label="开票抬头:" prop="company">
                                        <el-input v-model="invoiceInfo.company"></el-input>
                                    </el-form-item>

                                    <el-form-item label="税号:" prop="tax_number">
                                        <el-input v-model="invoiceInfo.tax_number"></el-input>
                                    </el-form-item>

                                    <el-form-item label="单位地址:">
                                        <el-input v-model="invoiceInfo.address"></el-input>
                                    </el-form-item>

                                    <el-form-item label="电话号码:">
                                        <el-input v-model="invoiceInfo.tel"></el-input>
                                    </el-form-item>

                                    <el-form-item label="开户银行:">
                                        <el-input v-model="invoiceInfo.company_bank"></el-input>
                                    </el-form-item>

                                    <el-form-item label="银行账号:">
                                        <el-input v-model="invoiceInfo.bank_account"></el-input>
                                    </el-form-item>

                                    <el-form-item label="邮箱:" prop="email">
                                        <el-input v-model="invoiceInfo.email"></el-input>
                                    </el-form-item>

                                    <el-form-item class="confirm_btns">
                                        <el-button type="primary" @click="sub_invoice_form()">保存</el-button>
                                    </el-form-item>
                                </el-form>
                            </div>

                            <!-- 有发票 -->
                            <div class="has_invoice" v-else-if="has_invoice === true">
                                <div class="invoice_form_view">
                                    <div for="username" class="col-form-label">公司名称:</div>
                                    <p>{{ invoiceInfo.company }}</p>
                                </div>

                                <div class="invoice_form_view">
                                    <div for="username" class="col-form-label">公司税号:</div>
                                    <p>{{ invoiceInfo.tax_number }}</p>
                                </div>

                                <div class="invoice_form_view">
                                    <div for="username" class="col-form-label">单位地址:</div>
                                    <p>{{ invoiceInfo.address ? invoiceInfo.address : "/" }}</p>
                                </div>

                                <div class="invoice_form_view">
                                    <div for="username" class="col-form-label">电话号码:</div>
                                    <p>{{ invoiceInfo.tel ? invoiceInfo.tel : "/" }}</p>
                                </div>

                                <div class="invoice_form_view">
                                    <div for="username" class="col-form-label">开户银行:</div>
                                    <p>
                                        {{
                                        invoiceInfo.deposit_bank ? invoiceInfo.deposit_bank : "/"
                                        }}
                                    </p>
                                </div>

                                <div class="invoice_form_view">
                                    <div for="username" class="col-form-label">银行账号:</div>
                                    <p>
                                        {{
                                        invoiceInfo.bank_account ? invoiceInfo.bank_account : "/"
                                        }}
                                    </p>
                                </div>

                                <div class="invoice_form_view">
                                    <div for="username" class="col-form-label">电子邮箱:</div>
                                    <p>{{ invoiceInfo.email }}</p>
                                </div>

                                <div class="invoice_form_view">
                                    <div for="username" class="col-form-label"></div>
                                    <div class="edit_btns d-flex">
                                        <div class="item_btn left_btn" @click="changeInvoice(true, 'edit')">修改</div>
                                        <div class="item_btn" @click="changeInvoice(true)">重填</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 

            <!-- 选择优惠券 -->
            <div class="com_box" v-if="in_show || after_show">
                <div class="com_title">选择优惠券<div class="trans_line"></div></div>

                <div class="box_main coupon_choose">
                    <ul class="coupon_list" v-if="in_show">
                        <li :class="[item.coupons.use_target == '会员' ? 'vip' : '', item.check || check_coupon ? 'active' : '']" v-for="(item, idx) in in_list" :key="idx" @click="change_coupon(item)">
                            <div class="coupon_box">
                                <div class="coupon_top">
                                    <p class="name"><i class="vip_icon iconfont icon-VIP" v-if="item.coupons.use_target == '会员'"></i>  {{item.name || '优惠券'}}</p>
                                    <div class="money d-flex">
                                        <div class="price">￥<span>{{item.dec_money}}</span></div>
                                        <div class="cond">满{{item.threshold_money}}可用</div>
                                    </div>
                                </div>
                                <div class="coupon_bottom">
                                    <p class="font time">有效日期：<span class="val">{{item.start_time}}-{{item.end_time}}</span></p>
                                    <p class="font obj">适用对象：<span class="val">{{ item.coupons.use_target }}</span></p>
                                    <p v-if="item.apply && item.apply.length <= 14" class="font apply">适用范围：<span class="val">{{ item.apply }}
                                        </span></p>
                                    <el-tooltip popper-class="user_pop" v-else class="item" effect="dark" :content="item.apply" placement="bottom-start">
                                        <p class="font apply">适用范围：<span class="val">{{ item.apply }}
                                        </span></p>
                                    </el-tooltip>
                                </div>
                            </div>
                            <img v-if="item.check" class="active_img" src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202111/13/%E9%A3%9E%E4%B9%A620211118-131157.png" alt=""/>
                        </li>
                    </ul>

                    <ul class="coupon_list after_list" v-if="after_show && more">
                        <li :class="item.coupons.use_target == '会员' ? 'vip' : ''" v-for="(item, idx) in after_list" :key="idx">
                            <div class="coupon_box">
                                <div class="coupon_top">
                                    <p class="name"><i class="vip_icon iconfont icon-VIP" v-if="item.coupons.use_target == '会员'"></i>  {{item.name || '优惠券'}}</p>
                                    <div class="money d-flex">
                                        <div class="price">￥<span>{{item.dec_money}}</span></div>
                                        <div class="cond">满{{item.threshold_money}}可用</div>
                                    </div>
                                </div>
                                <div class="coupon_bottom">
                                    <p class="font time">有效日期：<span class="val">{{item.start_time}}-{{item.end_time}}</span></p>
                                    <p class="font obj">适用对象：<span class="val">{{ item.coupons.use_target }}</span></p>
                                    <p v-if="item.apply.length <= 14" class="font apply">适用范围：<span class="val">{{ item.apply }}
                                        </span></p>
                                    <el-tooltip popper-class="user_pop" v-else class="item" effect="dark" :content="item.apply" placement="bottom-start">
                                        <p class="font apply">适用范围：<span class="val">{{ item.apply }}
                                        </span></p>
                                    </el-tooltip>
                                </div>
                                <div class="coupon_err">
                                    <p class="red">本单不可用原因：</p>
                                    <p class="font">指定分类商品金额未达使用门槛</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="action" v-if="after_show && in_show">
                        <div class="more" v-if="!more" @click="more = true">展开更多优惠券<i class="iconfont icon-xl12"></i></div>
                        <div class="less" v-else @click="more = false">收起<i class="iconfont icon-sl13"></i></div>
                    </div>

                </div>
            </div>

            <!-- 支付方式 -->
            <div class="com_box">
                <div class="com_title">支付方式<div class="trans_line"></div></div>

                <div class="box_main pay_choose d-flex">
                    <div
                    class="pay_item"
                    :class="{ active_pay: pay_type == 'ali_pay' }"
                    @click="choose_pay('ali_pay')"
                    >
                    <img class="icon_img" src="@/assets/images/ali_icon.png" alt="" />
                    支付宝支付

                    <img
                        v-if="pay_type == 'ali_pay'"
                        class="active_img"
                        src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202111/13/%E9%A3%9E%E4%B9%A620211118-131157.png"
                        alt=""
                    />
                    </div>

                    <div
                    class="pay_item"
                    :class="{ active_pay: pay_type == 'wx_pay' }"
                    @click="choose_pay('wx_pay')"
                    >
                    <img
                        class="icon_img wechat"
                        src="@/assets/images/wechat2.png"
                        alt="微信"
                    />
                    微信支付

                    <img
                        v-if="pay_type == 'wx_pay'"
                        class="active_img"
                        src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202111/13/%E9%A3%9E%E4%B9%A620211118-131157.png"
                        alt=""
                    />
                    </div>
                </div>

                
            </div>

            <!-- 商品订单信息金额展示 -->
            <div class="bottom_money">
                <div class="info_box">
                    <div>
                        <span class="count">{{ goods_num }}</span> 个商品 商品总金额：
                    </div>
                    <div class="right_money">¥{{ baseInfo.goods_money }}</div>
                </div>

                <div class="info_box">
                    <div>运费：</div>
                    <div class="right_money">¥{{ baseInfo.freight_money }}</div>
                </div>

                <div class="info_box" v-if="check_coupon != ''">
                    <div>优惠券：</div>
                    <div class="right_money right_coupon">{{counpon_txt}}

                        <!-- <el-select class="coupon" v-model="check_coupon" @change="change_coupon">
                            <el-option value="" label="不使用优惠券" > </el-option>
                            <el-option
                                v-for="v in baseInfo.user_coupons"
                                :key="v.id"
                                :value="v.id"
                                :label="`满${v.threshold_money}减${v.dec_money}`"
                            ></el-option>
                        </el-select> -->
                    </div>
                </div>
                <div class="info_box" v-if="baseInfo.member_level_discount && baseInfo.member_level_discount != 1 && baseInfo.member_level_discount != 0">
                    <div>会员折扣：</div>
                    <div class="right_money">{{baseInfo.member_level_discount*100}}折</div>
                </div>

                <div class="info_wrap">
                    <div class="info_box d-flex">
                        <div><input type="checkbox" v-model="check_ebc" @click="change_ebc(check_ebc)"/>E币折扣：</div>
                        <div class="right_money">-￥{{baseInfo.allow_ebc}}</div>
                    </div>
                    <div class="ebc">当前账户E币 <span>{{baseInfo.user_ebc}}</span>，该笔订单可使用{{ baseInfo.allow_ebc }}E币抵扣</div> 
                </div>

                <div class="info_box">
                    <div>实付款：</div>
                    <div class="right_money total_money">
                    ¥<span>{{ baseInfo.total_money_after }}</span>
                    </div>
                </div>

                <div class="delivery_txt" v-if="addressList.length > 0">
                    收货地址：<span>
                    {{
                        addressList.length > 0
                        ? addressList[active_index].district.replace(/[/]/g,"")

                        : ""
                    }}
                    {{
                        addressList.length > 0
                        ? addressList[active_index].address
                        : ""
                    }}
                    </span>
                </div>

                <div class="delivery_txt">
                    收件人：<span>
                    {{
                        addressList.length > 0
                        ? addressList[active_index].name
                        : ""
                    }}
                    {{
                        addressList.length > 0
                        ? addressList[active_index].tel
                        : ""
                    }}
                    </span>
                </div>
            </div>

            <!-- 支付订单 -->
            <div class="pay_btn">
                <el-button class="btn btn-primary" @click="pay_order()">
                    支付订单
                </el-button>
                <p class="btn_deal">支付后将会生成订单合同，查看<span @click="dealOpen">"订单合同模版”</span></p>
            </div>
        </div>
        <foot active="pay" />
        <toolbar />

        <el-dialog custom-class="modal_pay" v-model="pay_diy" :before-close="payClose"> 
            <template v-slot:title>
                <div class="dialog-title">微信支付</div>
            </template>  
            <div class="dialog-content">
                <div class="erweima_img">
                    <div class="svg_box" v-html="wx_pay_erweima"></div>
                </div>

                <p><img src="@/assets/images/paywx.png" alt="" />微信支付</p>
            </div>
        </el-dialog>

        <el-dialog custom-class="modal_deal" v-model="pay_deal" :before-close="dealClose"> 
            <template v-slot:title>
                <div class="dialog-title">订单合同模板</div>
            </template>  
            <div class="dialog-content">
                <img src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202201/17/%E9%A3%9E%E4%B9%A620220118-084526.png" alt="" />
            </div>
        </el-dialog>



        <!-- 收货地址 -->
        <addaddress
            v-if="address_modal"
            ref="addaddress"
            :form="address_form"
            @changeModal="changeAddress"
        ></addaddress>

        <!-- 发票信息 -->
        <addinvoice
            v-if="invoice_modal"
            ref="invoice"
            :info="invoiceInfo"
            :type="invoiceType"
            active="pay"
            @changeModal="changeInvoice"
        ></addinvoice>
    </div>
</div>
</template>

<script>
import topnav from "@/components/Nav.vue";
import topmenu from "@/components/Menu.vue";
import foot from "@/components/Footer.vue";
import toolbar from "@/components/Toolbar.vue";
import addaddress from "@/components/AddAddress.vue";
import addinvoice from "@/components/AddInvoice.vue";
import { getData, postData } from "@/api/user";
import dataCity from "@/assets/city";

import { toRefs, reactive, ref, computed } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

import { ElMessage } from 'element-plus'

export default {
    name: "pay",
    setup() {
        const store = useStore()
        const route = useRoute()
        const router = useRouter()
        const form_addr = ref(null)
        const form_invoice = ref(null)
        store.commit('setCount');


        var checkFixTel = (rule, value, callback) => {
            if (!value) {
                return callback(new Error("请输入手机/固话"));
            }
            var mobile = /^1[345789]\d{9}$/,
                phone = /^0\d{2,3}-?\d{7,8}$/;
            if (mobile.test(value) || phone.test(value)) {
                callback();
            } else {
                return callback(new Error("请正确输入手机号/固话！"));
            }
        };

        var checkDuty = (rule, value, callback) => {
            if (!value) {
                return callback(new Error("请输入公司税号"));
            }
            const duty = /^[A-Za-z0-9]+$/;
            if (duty.test(value)) {
                callback();
            } else {
                return callback(new Error("公司税号税号只能是数字和字母！"));
            }
        };

        var checkTel = (rule, value, callback) => {
            if (!value) {
                return callback(new Error("请输入电话号码"));
            }
            var mobile = /^1[345789]\d{9}$/,
                phone = /^0\d{2,3}-?\d{7,8}$/;
            if (mobile.test(value) || phone.test(value)) {
                callback();
            } else {
                return callback(new Error("请正确输入电话号码！"));
            }
        };

        var checkEmail = (rule, value, callback) => {
            if (!value) {
                return callback(new Error("请输入邮箱地址"));
            }

            let reg = /^\w+@[a-zA-Z0-9]{2,10}(?:\.[a-z]{2,4}){1,3}$/;

            if (reg.test(value)) {
                callback();
            } else {
                return callback(new Error("请输入正确的邮箱！"));
            }
        };

        let state = reactive({
            userid: store.state.userInfo.id,
            active: "car_pay",
            query_info: route.query, // 路由参数
            baseInfo:{},


            // 地址
            options: dataCity,
            address_modal: false,
            address_form: {}, // 当前 收货地址
            address_rules: {
                name: [{ required: true, message: "请输入收货人", trigger: "blur" }],
                district: [{ required: true, message: "请选择所在地区", trigger: "blur" },],
                address: [{ required: true, message: "请输入详细地址", trigger: "blur" },],
                tel: [{ required: true, validator: checkFixTel, trigger: "blur" }],
            },
            address_show: "",
            active_index: 0, // 寄送的地址
            addressList: [],

            // 发票
            check_type:'不开票',
            invoice_modal: false,
            choose_invoice: false, // 是否选择开票
            has_invoice: true, // 有发票信息
            invoiceType: "",
            invoiceInfo: {
                company: "",
                tax_number: "",
                address: "",
                tel: "",
                company_bank: "",
                bank_account: "",
                email: "",
            },
            invoice_rules: {
                company: [{ required: true, message: "请输入公司名称", trigger: "change" },],
                tax_number: [{ required: true, validator: checkDuty, trigger: "change" },],
                tel: [{ required: true, validator: checkTel, trigger: "change" }],
                email: [{ required: true, validator: checkEmail, trigger: "change" }],
            },

            // 产品
            shoppingCarList: [],

            // 支付
            pay_type: "ali_pay",
            pay_diy: false, // 微信支付弹框
            wx_pay_erweima: "", // 微信支付 二维码
            inter_login: "", // 轮询定时器

            // 优惠券
            in_list: [],
            in_show: true,
            after_list:[],
            after_show: true,
            more: false,
            check_coupon: '',
            counpon_txt:'',

            // 提交
            check_ebc:true,

            // 合同模板
            pay_deal: false
        });

        let goods_num = computed(() => {
            let num = 0;
            state.shoppingCarList.forEach((val) => {
                num += val.num;
            });
            return num;
        })

        let get_info = () => {
            getData({
                url: "api/order/get_shopping_car_base",
                data:  {
                    shopping_car_id_ary:route.query.shopping_car_id_ary ? JSON.parse(route.query.shopping_car_id_ary): [],
                    choose_user_coupon:state.check_coupon,
                    choose_ebc: state.check_ebc ? 1 : 0,
                    need_invoice: state.check_type == '不开票' ? 0 : 1,
                    invoice_type: state.check_type,
                }
            }).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    let info = data.data;
                    if (info.invoiceInfo) {
                        state.invoiceInfo = info.invoiceInfo;
                        state.has_invoice = true;
                    } else {
                        state.has_invoice = false;
                    }
                    state.addressList = info.address;
                    state.address_show = info.address.length > 0 ? true : false;
                    state.shoppingCarList = info.shopping_car_base.goods_info;
                    state.baseInfo = info.shopping_car_base;

                    // 优惠券
                    state.in_list = state.baseInfo.user_coupons_in_time_allow_use
                    state.in_show = state.in_list && state.in_list.length > 0
                    state.after_list = state.baseInfo.user_coupons_in_time_forbid_use
                    state.after_show = state.after_list && state.after_list.length > 0

                    if(!state.in_show) {
                        state.more = state.after_show
                    }

                    if(state.in_list && state.in_list.length > 0) {
                        state.in_list.forEach((v) => {
                            v.check =  v.id == state.check_coupon
                            let str = ''
                            v.coupons.category_name.forEach((a,b)=>{
                                str += b < v.coupons.category_name.length -1 ? a+'/': a
                            })
                            v.apply = str
                        })
                    }

                    if(state.after_list && state.after_list.length > 0) {
                        state.after_list.forEach((v) => {
                            let str = ''
                            v.coupons.category_name.forEach((a,b)=>{
                                str += b < v.coupons.category_name.length -1 ? a+'/': a
                            })
                            v.apply = str
                        })
                    }
                    
                }
            });
        }

        let init = () => {
            if (state.userid) {
                get_info()
            } else {
                router.push({ path: "/" });
                window.scrollTo(100,0)
            }
        }
        init()

        // 选择地区
        let cascaderChange = (value) => {
            state.address_form.area = `${value[0]}/${value[1]}/${value[2]}`;
        }

        // 保存地址
        let sub_address_form = () => {
            form_addr.value.validate((valid) => {
                if (valid) {
                    let url = `api/my_home/my_address`;
                    let params = {
                        name: state.address_form.name,
                        district: state.address_form.area,
                        address: state.address_form.address,
                        tel: state.address_form.tel,
                        postcode: state.address_form.postcode,
                        default: "是",
                    };

                    postData({
                        url: url,
                        data: params,
                    }).then((res) => {
                        let data = res.data;
                        if (data.code === 200) {
                            ElMessage.success(data.data.msg);
                            changeAddress(false)
                        } else if (data.code === 422) {
                            ElMessage.error(data.data.msg);
                        }
                    });
                } else {
                    return false;
                }
            });
        }

        // 新增地址弹窗
        let changeAddress = (v) => {
            state.address_modal = v;
            if (!v) {
                get_info();
            }
        }
        
        // 选择寄送地址
        let toggle_address = (index) => {
            state.active_index = index;
        }

        // 选择发票类型
        let change_type = (v) => {
            state.check_type = v
        }

        let changeInvoice = (v, type) => {
            state.invoice_modal = v;
            state.invoiceType = type;

            if (!v) {
                init();
            }
        }

        let sub_invoice_form = () => {
            form_invoice.value.validate((valid) => {
                if (valid) {
                    let url = `api/invoices`;
                    let params = {
                        company: state.invoiceInfo.company,
                        tax_number: state.invoiceInfo.tax_number,
                        address: state.invoiceInfo.address,
                        tel: state.invoiceInfo.tel,
                        deposit_bank: state.invoiceInfo.deposit_bank,
                        bank_account: state.invoiceInfo.bank_account,
                        email: state.invoiceInfo.email
                    };
                    postData({
                        url: url,
                        data: params,
                    }).then((res) => {
                        let data = res.data;
                        if (data.code === 200) {
                            ElMessage.success(data.data.msg);
                            get_info()
                        } else if (data.code === 422) {
                            ElMessage.error(data.data.msg);
                        }
                    });
                } else {
                    return false;
                }
            });
        }

        // 支付方式
        let choose_pay = (type) => {
            state.pay_type = type;
        }

        let payClose = () => {
            state.pay_diy = !state.pay_diy;
            if (!state.pay_diy) {
                clearInterval(state.inter_login);
            }
        }

        // 支付订单
        let pay_order = () => {
            if (!state.has_invoice && state.check_type !='不开票') {
                ElMessage.error("请填写发票信息");
                return;
            }

            if (state.addressList.length <= 0) {
                ElMessage.error("请填写收货地址");
                return;
            }

            postData({
                url: "api/order/check_stock",
                data: {
                    shopping_car_id_ary:state.query_info.shopping_car_id_ary ? JSON.parse(state.query_info.shopping_car_id_ary):[],
                },
            }).then((res) => {
                let data = res.data;
                if (data.code === 200) {

                    let params = {
                        shopping_car_id_ary:state.query_info.shopping_car_id_ary ? JSON.parse(state.query_info.shopping_car_id_ary):[],
                        choose_user_coupon: state.check_coupon,
                        choose_ebc: state.check_ebc ? 1: 0,
                        address_id: state.addressList[state.active_index].id,
                        need_invoice: state.check_type == '不开票' ? 0 : 1,
                        invoice_type: state.check_type,
                        invoice_id: state.check_type == '不开票' ? 0 : state.invoiceInfo.id,
                        pay_type: state.pay_type == "wx_pay" ? "微信" : "支付宝",
                    };

                    if (state.pay_type === "ali_pay") {
                        let card_arr = JSON.parse(state.query_info.shopping_car_id_ary);
                        let url = `https://chip.api.ebaina.com/api/order/pay?shopping_car_id_ary=${card_arr}&choose_user_coupon=${params.choose_user_coupon}&choose_ebc=${params.choose_ebc}&address_id=${params.address_id}&need_invoice=${params.need_invoice}&invoice_type=${params.invoice_type}&invoice_id=${params.invoice_id}&pay_type=支付宝&user_id=${state.userid}`;

                        location.href = url;
                        return;
                    }

                    postData({
                        url: "api/order/pay",
                        data: params,
                    }).then((res) => {
                        let data = res.data;
                        if (data.code === 200) {
                            payClose();
                            state.wx_pay_erweima = data.data.url;
                            get_inter_login(data.data.order_id);
                        } else if (data.code === 422) {
                            ElMessage.error(data.data.msg);
                        } else {
                            ElMessage.error(data.data.msg);
                        }
                    });

                } else if (data.code === 422) {
                    ElMessage.error(data.data.msg);
                } else {
                    ElMessage.error(data.data.msg);
                }
            });
        }

        // 获取支付状态
        let get_inter_login = (order_id) => {
            state.inter_login = setInterval(() => {
                getData({
                    url: `api/order/get_order_by_id/${order_id}`,
                    data: {},
                }).then((res) => {
                    let data = res.data;
                    if (data.data.order.pay_status == "已支付") {
                        clearInterval(state.inter_login);
                        state.pay_diy = false
                        ElMessage.success('支付成功，页面正在跳转')
                        setTimeout(() => {
                            router.push({ path: "/user", query: { temp: "UserOrder", id: 4 }, });
                            window.scrollTo(100,0)
                        }, 2000);
                    }
                    // if (data.data.result) {
                    //   clearInterval(state.inter_login);
                    // }
                });
            }, 3000);
        }

        // 贴片合同 
        let dealOpen = () => {
            state.pay_deal = true
        }

        let dealClose = () => {
            state.pay_deal = false
        }

        let to_detail = (v) => {
            router.push({
                path: '/detail',
                query: {
                    id: v.goods_id
                }
            })
            window.scrollTo(100,0)
        }

        // 更改优惠券
        let change_coupon = (v) => {
            if(v.check) {
                v.check = false
                state.check_coupon = ''
                state.counpon_txt = ''
            }else {
                state.in_list.forEach((v)=> {
                    v.check = false
                })
                v.check = true
                state.check_coupon = v.id
                state.counpon_txt = `满${v.coupons.threshold_money}减${v.coupons.dec_money}`
            }
            get_info()
        }

        let change_ebc = (bol) => {
            state.check_ebc = !bol
            get_info()
        }

        

        return {
            goods_num,
            ...toRefs(state),
            get_info,
            changeAddress,
            sub_address_form,
            toggle_address,
            changeInvoice,
            sub_invoice_form,
            choose_pay,
            cascaderChange,
            pay_order,
            form_invoice,
            form_addr,
            change_type,
            to_detail,
            change_coupon,
            change_ebc,
            dealClose,
            dealOpen
        }
    },
    components: {
        topnav,
        topmenu,
        foot,
        addaddress,
        addinvoice,
        toolbar
    }
};

</script>

<style lang="less" scoped>

input[type="checkbox"] {
    margin: 0 12px 0 0;
    cursor: pointer;
    position: relative;
    width: 15px!important;
    height: 15px!important;
    font-size: 14px;
}

input[type="checkbox"]::after {
    position: absolute;
    top: 0;
    color: #fff;
    background-color: #fff;
    width: 17px;
    height: 17px;
    display: inline-block;
    visibility: visible;
    text-align: center;
    content: " ";
    border: 1px solid #d1d1d1;
}

input[type="checkbox"]:checked::after {
    content: "✓";
    font-size: 12px;
    font-weight: bold;
    background-color: #1C46B7;
}

.pay {
    padding: 154px 0 440px;
    background:#F8F8F8;

    .pay_wrap {
        padding: 40px 0;
    }

    .pay_title {
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        color: #333;
        margin-bottom: 30px;
    }

    .settlement_main {
        .com_box {
            margin-bottom: 30px;

            .com_title {
                position: relative;
                display: inline-block;
                height: 40px;
                line-height: 40px;
                background: #ffffff;
                font-size: 16px;
                font-weight: 600;
                text-align: center;
                color: #333333;
            }
        }
        /*没地址 */
        .box_main {
            padding: 40px 24px 33px;
            background: #fff;

            /deep/ .el-form {
                .el-form-item {
                .el-input,
                .el-cascader {
                    width: 234px;
                }
                }
            }
        }

        /* 有地址 */
        .has_address_box {
            cursor: pointer;

            .item_address {
                margin-bottom: 13px;
                padding: 5px 16px;
                display: flex;
                align-items: center;
                border: 1px solid transparent;

                .left_txt {
                    margin-right: 22px;
                    font-size: 14px;
                    color: #fff;
                    visibility: hidden;
                    flex-shrink: 0;
                }

                .readio_box {
                    position: relative;
                    margin-right: 10px;
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    background-color: #fff;
                    border: 1px solid #a9a9a9;
                    overflow: hidden;
                    flex-shrink: 0;

                    .com_radio {
                        width: 100%;
                        height: 100%;
                    }

                    .active_radio {
                        display: none;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 4px;
                        height: 4px;
                        border-radius: 50%;
                        background-color: #fff;
                    }
                }

                .address_box {
                    font-size: 14px;
                    font-weight: 400;
                    color: #666666;
                }

                .default_address {
                    margin-left: 13px;
                    font-size: 14px;
                    color: #013EAA;
                    flex-shrink: 0;
                    margin-left: auto;
                }
            }

            /* 选中的地址 */
            .active_address {
                border: 1px solid #013EAA;
                background: #f5faff;

                .left_txt {
                    visibility: initial;
                    color: #013EAA;
                }

                .readio_box {
                    background: #013EAA;
                    border: 1px solid #013EAA;

                    .active_radio {
                    display: block;
                    }
                }
            }

            /* 新增收货地址 */
            .add_address_btn {
                display: inline-block;
                margin-top: 10px;
                margin-left: 80px;
                padding: 0 14px;
                height: 30px;
                line-height: 30px;
                text-align: center;
                font-size: 14px;
                font-weight: 600;
                color: #013EAA;
                background: #ffffff;
                border: 1px solid #013EAA;
                border-radius: 3px;
                cursor: pointer;
            }
        }

        /deep/ .el-radio {
            height: auto;
        }

        /deep/ .el-radio__input.is-checked .el-radio__inner {
                background: #013EAA;
                border: 1px solid #013EAA;
        }

        /deep/ .el-radio__input.is-checked+.el-radio__label {
            color: #013EAA;
        }


        .choice {
            .choice_title {
                line-height: 40px;
                margin-right: 20px;
            }
        }

        /* 增值税 */
        .invoice_box {
            .box_main {
                padding: 40px 24px 33px;

                
                .warm_prompt {
                    margin: 20px 0;
                    font-size: 14px;
                    font-weight: 600;
                    color: #ffb34b;
                }



                .check_detail {
                    background: #F5FAFF;
                    padding: 18px 22px;
                }

                /deep/ .confirm_btns {
                    .el-button {
                    padding: 0;
                    width: 68px;
                    height: 32px;
                    line-height: 32px;
                    font-size: 12px;
                    text-align: center;
                    border: 1px solid #eaeaea;
                    border-radius: 3px;
                    color: #fff;
                    background: #013EAA;
                    }
                }

                /* 有发票 */
                .has_invoice {
                    .invoice_form_view {
                    display: flex;
                    align-items: center;

                    .col-form-label {
                        padding: 0;
                        width: 90px;
                        line-height: 32px;
                        font-size: 13px;
                        font-weight: 400;
                        color: #666;
                        flex-shrink: 0;
                    }

                    p {
                        font-size: 14px;
                        color: #333333;
                    }
                    }

                    .edit_btns {
                    margin-top: 20px;

                    .item_btn {
                        padding: 0 15px;
                        font-size: 13px;
                        color: #013EAA;
                        height: 14px;
                        line-height: 1;
                        cursor: pointer;
                    }

                    .left_btn {
                        padding-left: 0;
                        border-right: 1px solid #707070;
                    }
                    }
                }
            }

            
        }

        /* 支付方式 */
        .pay_choose {
            position: relative;
            .pay_item {
            position: relative;
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 200px;
            height: 70px;
            font-size: 16px;
            font-weight: 700;
            color: #333333;
            border: 1px solid #eaeaea;
            flex-shrink: 0;
            cursor: pointer;

            .icon_img {
                margin-right: 11px;
                width: 28px;
                height: 28px;
            }

            .active_img {
                position: absolute;
                top: 0;
                right: 0;
                width: 46px;
                height: 46px;
            }
            }

            .active_pay {
            background: #F5FAFF;
            border: 2px solid #316DFA;

            .active_img {
                display: block !important;
            }
            }
        }
    }

    .pay_table {
        margin-bottom: 20px;
        // border: 1px solid #eaeaea;

        .payMain_hd {
            width: 100%;
            height: 32px;
            line-height: 32px;
            background: #f5f5f5;
            li {
                font-size: 12px;
                color: #666666!important;
                white-space: nowrap;
                display: flex;
                align-items: center;
                justify-content: center;
                line-height: 32px;
                height: 32px;
            }

            .order_lists {
                padding: 0;
            }

            .payTop {
                height: 32px;
                .list_chk {
                    display: inline-block;
                }
            }
        }

        .order_lists {
            display: flex;
            width: 100%;
            padding: 20px 0 15px 0;
            border-bottom: 1px solid #eaeaea;
            overflow: hidden;
            align-items: flex-start;

            .list_chk {
                width: 100px;
                display: inline-block;
            }

            li {
                padding: 0 20px;
                font-size: 12px;
                color: #333;
                line-height: 18px;
                justify-content: center;
            }

            /* 图片 */
            .list_con {
                cursor:pointer;
                align-items: center;
                width: 180px;
                text-align: center;

                img {
                    display: inline-block;
                    width: 80px;
                    height: 80px;
                    border: 1px solid #eaeaea;
                    object-fit: contain;
                    flex-shrink: 0;
                }
            }

            .list_product {
              width: 250px;
              justify-content: flex-start; 
            }


            /* 产品名称*/
            .list_name {
                cursor:pointer;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                margin-bottom: 5px;
                font-weight: 600;
            }

            .list_name:hover {
              color:#3385F6;
            }

            .list_item {
              margin-bottom: 5px;
              .title {
                display: inline-block;
                width: 60px;
                font-size: 12px;
                font-weight: 400;
                line-height: 17px;
                color: #666666;
              }
              .val {
                font-size: 12px;
                font-weight: 400;
                line-height: 17px;
                color: #333333;
              }
              
            }

            /* 参数描述 */
            .list_desc {
                width: 250px;
                justify-content: flex-start; 
                cursor: pointer;
            }

            /* 单价 */
            .list_price {
                width: 200px;
                text-align: center;

            }

            /* 库存 */
            .list_num {
                width: 200px;
                text-align: center;
            }

            .list_total {
                width: 200px;
                text-align: center;
                .sum_price {
                    font-size: 13px;
                    color: #FF7F23;
                    font-weight: bold;
                    line-height: 1;
                }
            }

        }

        .order_lists:last-child {
            border-bottom: none;
        }

        
    }

    .bottom_money {
        padding: 30px 30px 20px;
        background: #fff;
        border: 1px solid #DDDDDD;
        text-align: right;

        .info_box {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-bottom: 16px;
            font-size: 14px;
            color: #666666;
            line-height: 1;

            .count {
                color: #013EAA;
            }

            .right_money {
                margin-left: 30px;
                min-width: 160px;
                flex-shrink: 0;
            }
            .right_coupon {
                color: #FF7F23;
            }

            /deep/ .el-input__inner,
            /deep/.el-input {
                height: 28px;
                line-height: 28px;
                width: 160px;
            }

            .total_money {
                color: #FF7F23;

                span {
                    font-size: 20px;
                }
            }
        }

        .ebc {
            font-size: 12px;
            line-height: 17px;
            color: #999999;
            margin-top: -10px;
            margin-bottom: 16px;
            span {
                color: #FF7F23;
            }
        }

        .delivery_txt {
            margin: 12px 0 0;
            font-size: 14px;
            color: #333333;
            line-height: 1;
        }
    }

    .pay_btn {
        text-align: right;

        .btn {
            padding: 0;
            width: 181px;
            height: 42px;
            line-height: 42px;
            color: #fff;
            text-align: center;
            background: #013EAA;
            border: 1px solid #013EAA;
            border-radius: 0;
        }

        .btn_deal {
          font-size: 13px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 18px;
          color: #333;
          margin-top: 20px;
          span {
              color: #3385F6;
              text-decoration: underline;
              cursor:pointer;
          }
          
        }
    }
}

.com_box {
    margin-bottom: 30px;

    .com_title {
    position: relative;
    display: inline-block;
    padding: 0 17px;
    height: 40px;
    line-height: 40px;
    background: #ffffff;
    border: 1px solid #DDDDDD;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: #333333;

    .trans_line {
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 100%;
        background: #fff;
        height: 2px;
        z-index: 2;
    }
    }

    .box_main {
    background: #ffffff;
    border: 1px solid #DDDDDD;


    /deep/ .el-form {
        .el-form-item {
        .el-input,
        .el-cascader {
            width: 234px;
        }
        }
    }
    }
}

.coupon_list {
    width: 100%;
    li {
        position: relative;
        display: inline-block;
        min-height: 154px;
        width: 270px;
        margin: 0 20px 30px 0;
        color: #fff;
        cursor: pointer;
        border: 1px solid #E6E6E6;
        .coupon_box {
            height: 100%;
            width: 100%;
        }
        .coupon_top {
            position: relative;
            width: 100%;
            padding: 9px 15px 6px;
            height: 86px;
            background: #559BFF;
        }
        .name {
            font-size: 16px;
            font-weight: 600;
            line-height: 22px;
            color: #FFFFFF;
            text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);
        }
        .money {
            align-items: center;
            justify-content: flex-start;
        }
        .price {
            font-size: 14px;
            font-weight: 600;
            line-height: 46px;
            text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);
            margin-right: 20px;
            span {
                font-size: 30px;
            }
        }
        .cond {
            height: 30px;
            padding: 6px;
            border: 1px solid #FFFFFF;
            border-radius: 4px;
            font-size: 12px;
            font-weight: 600;
            line-height: 17px;
            color: #FFFF;
        }
        .coupon_bottom {
            padding: 4px 7px 7px 13px;
        }
        .font {
            font-size: 12px;
            font-weight: 400;
            line-height: 17px;
            color: #999999;
            margin-bottom: 3px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            .val {
                color: #666666;
            }
        }
        .to {
            position: absolute;
            right: 15px;
            top: 9px;
            width: 28px;
            height: 70px;
            border: 1px solid #FFFFFF;
            opacity: 0.8;
            border-radius: 15px;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: #FFFFFF;
            text-align:center;
            
        }
        .active_img {
            position: absolute;
            top: 0;
            right: 0;
            width: 46px;
            height: 46px;
        }

        .coupon_err {
            border-top: 1px solid #E6E6E6;
            padding: 7px 12px 9px;
            .red{
                font-size: 11px;
                font-weight: 400;
                line-height: 16px;
                color: #FF0000;
                margin-bottom: 4px;
            }
            .font {
                font-size: 11px;
                font-weight: 400;
                line-height: 16px;
                color: #666666;
            }
        }
    }

    li.vip {
        .coupon_top {
            position: relative;
            width: 100%;
            padding: 9px 15px 6px;
            height: 86px;
            background: linear-gradient(180deg, #EFC195 0%, #B47B4F 100%);
        }
    }
    li:hover {
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    }
    li.active {
        border: 2px solid #3B91FA;
    }

}
.after_list {
    li {
        cursor: default;
        .coupon_top {
            background: #D9D9D9;
        }
    }
    li.vip {
        .coupon_top {
            background: linear-gradient(180deg, #EAEAEA 0%, #B4B4B4 100%);
        }
    }
}

.action {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    color: #999999;
    text-align: center;
    cursor: pointer;

    .iconfont {
        font-size: 12px;
        margin-left: 5px;
    }
}
</style>


<style lang="less">
.el-popper.is-light.pb_pop {
    font-size: 12px !important;
}

.modal_pay {
    width: 345px;
    height: 320px;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    border-radius: 4px;

    .el-dialog__header {
        padding: 18px 24px 24px;
        .dialog-title {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        color: #333333;
        }
    }
    .el-dialog__body {
        padding: 0 24px;
    }
    .el-dialog__footer {
        padding: 0 24px 18px;
    }

    .dialog-content {
        text-align: center;
        font-size: 13px;
        line-height: 18px;
        padding-bottom: 20px;
        color: #666;

        span {
            color: #ef0016;
        }

        .erweima_img {
            margin: auto;
            padding: 10px;
            width: 166px;
            height: 166px;
            border: 1px solid #629856;
            overflow: hidden;
            margin-top:11px;

            .svg_box {
                svg {
                    width: 100% !important;
                    height: 100% !important;
                }
            }
        }

        p {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 24px;
            font-size: 16px;
            color: #666;
            line-height: 1;

            img {
                margin-right: 7px;
                width: 26px;
                height: 22px;
            }
        }
    }
}

.modal_deal {
    width: 645px;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    border-radius: 4px;

    .el-dialog__header {
        padding: 18px 24px 24px;
        .dialog-title {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        color: #333333;
        }
    }
    .el-dialog__body {
        padding: 0 24px;
    }
    .el-dialog__footer {
        padding: 0 24px 18px;
    }

    .dialog-content {
      img {
        margin-right: 7px;
        width: 100%;
        height: 100%;
      }
    }
}

.el-popper.is-dark.user_pop {
    width: 330px!important;
    line-height: 16px;
}
</style>
